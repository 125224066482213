<template>
  <a-spin :spinning="spinning">
      
      <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :columns="columns"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a slot="totalCount" slot-scope="text">{{ text }}</a>
        <a-row :gutter="8" align="middle" type="flex">
         <!-- <a-col class="gutter-row" :span="4">
            <a-select
              style="width: 100%"
              placeholder="请选择审核状态"
              v-model="SearchDto.status"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in statusList"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>-->
          <a-col class="gutter-row" :span="4">
            <a-range-picker
              :placeholder="['开始日期', '结束日期']"
              @change="onPickerChange"
            />
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-input v-model="SearchDto.Name" :placeholder="l('姓名')" />
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-button type="primary" @click="search">
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          </a-col>
          <a-col class="gutter-row" :span="12" align="right">
            <a-button @click="BatchApply">
              <a-icon type="audit" />
              <span>{{ l("批量审核") }}</span>
            </a-button>
            <!--<a-button type="primary">
                        <a-icon type="file-excel" />
                        <span>{{ l("上传执行记录") }}</span>
                      </a-button>-->
          </a-col>
        </a-row>
      </react-table>
    <a-modal title="Title" :visible="showBatchApply" @cancel="hideBatchApply">
      <a-row :gutter="24">
        <a-col span="24">
          <label>不通过原因：</label>
        </a-col>
        <a-col span="24">
          <a-textarea :row="6" v-model="rejectReason"></a-textarea>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="handleSubmit(0)">不通过</a-button>
        <a-button key="submit" type="primary" @click="handleSubmit(1)"
          >通过</a-button
        >
      </template>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import ReactTable from "@/components/react-table";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import CollectionFormResult from "./collectionformresult.vue";

let vm = this;

export default {
  mixins: [AppComponentBase],
  name: "task-report-newpannel",
  props: ["taskId"],
  components: { ReactTable },
  data() {
    return {
      spinning: false,
      showBatchApply: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 30,
        Sorting: "",
        filterText: "",
        Name: "",
        startDate: "",
        endDate: "",
        status: undefined,
      },
      name: "",
      projectData: [
        // {
        //   value: "1",
        //   text: "测试项目1",
        // },
        // {
        //   value: "2",
        //   text: "测试项目2",
        // },
      ],
      selectedRowKeys: [],
      rejectReason: "",
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      child: "",
      commonService: null,
      selectProjectList: "",
      statusList: [
        { label: "未审核", value: 0 },
        { label: "审核通过", value: 1 },
        { label: "已结算", value: 2 },
        { label: "审核驳回", value: -1 },
      ],
    };
  },
  created() {
    console.log(this);
    // this.initChild();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    // this.getProject();
    this.getData();
    this.initActionsType();
  },
  methods: {
    initColumns() {
      var vm = this;
      //   var child = vm.$createElement("a", {
      //     domProps: {
      //       innerHTML: 66,
      //     },
      //     on: {
      //       click: function () {
      //         vm.signClick();
      //       },
      //     },
      //   });
      this.columns = [
          {
          title: this.l("任务编码"),
          dataIndex: "taskCode",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "taskCode" },
         },
         {
          title: this.l("任务名称"),
          dataIndex: "taskTitle",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "taskTitle" },
         },
         {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "projectName" },
         },
          {
          title: this.l("所属客户"),
          dataIndex: "customerName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "customerName" },
         },
        {
          title: this.l("姓名"),
          dataIndex: "realName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("执行名称"),
          dataIndex: "executeRecordName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "executeRecordName" },
        },
        {
          title: this.l("执行时间"),
          dataIndex: "executeTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "executeTime" },
        },
        {
          title: this.l("状态"),
          dataIndex: "isAuditedStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "isAudited" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    initChild(text) {
      var child = this.$createElement("a", {
        domProps: {
          innerHTML: "点击查看",
        },
        on: {
          click: function () {
            this.signClick(text);
          },
        },
      });
      this.child = child;
      console.log(child);
    },
    search() {
      this.pageNumber = 1
      this.SearchDto.skipCount = 0
      this.getData()
    },
    getData() {
      this.getPaged();
      // this.tableData = [
      //   {
      //     name: "xxx",
      //     title: "酒店打扫效果验收",
      //     remark: "完成酒店打扫",
      //     picture: "",
      //     date: "2020-02-23 10:32",
      //     status: "未审核",
      //   },
      //   {
      //     name: "xxx",
      //     title: "酒店打扫效果验收",
      //     remark: "完成酒店打扫",
      //     picture: "",
      //     date: "2020-02-23 10:32",
      //     status: "已审核",
      //   },
      // ];
    },
    async getPaged() {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url:
            "/api/services/app/PersonnelTask/GetTaskPersonnelExecuteRecordsNotByTaskIdPaged",
          params: {
            taskId: this.taskId,
            status: this.SearchDto.status,
            name: this.SearchDto.Name,
            startDate: this.SearchDto.startDate,
            endDate: this.SearchDto.endDate,
            filterText: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        console.log(res);
        this.tableData = res.items.map((item) => {
          let auditStatus = "待审核";
          if (item.status == 1) {
            auditStatus = "审核通过";
          } else if (item.status == 2) {
            auditStatus = "已结算";
          } else if (item.status == -1) {
            auditStatus = "驳回";
          }
          return {
            ...item,
            executeTimeStr: item.executeTime
              ? moment(item.executeTime).format("YYYY-MM-DD")
              : "-",
            isAuditedStr: auditStatus,
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("查看详情"),
              icon: "eye",
              fn: (data) => {
                _this._getCollectionFormResult(data);
              },
            },
            // {
            //   granted: true,
            //   name: this.l("查看已签署人员"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this.lookSignPerson(data);
            //   },
            // },
            // {
            //   granted: true,
            //   name: this.l("查看未签署人员"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this.lookSignPerson(data);
            //   },
            // },
          ],
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData(newV) {
      if (newV.columnKey == "isAuditedStr") {
        newV.columnKey = "auditStatus";
      }
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * this.SearchDto.MaxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    lookSign(item) {
      const { pdfTemplateUrl } = item;
      if (pdfTemplateUrl) {
        window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
      } else {
        this.$message.info("暂无协议");
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      (this.SearchDto.Name = ""), (this.SearchDto.filterText = "");
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.status = null;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    signClick(item) {
      //   this.message.success(text);
      this.lookSignPerson(item, 1);
    },
    noSignClick(item) {
      //   this.message.success("测试");
      this.lookSignPerson(item, 0);
    },
    lookSignPerson(item, signstatus) {
      ModalHelper.create(
        signperonlist,
        {
          signPerson: item,
          signstatus: signstatus,
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    onPickerChange(date, dateString) {
      console.log(date, dateString);
      this.SearchDto.startDate = dateString[0];
      this.SearchDto.endDate = dateString[1];
    },
    _getCollectionFormResult(data) {
      console.log(data);
      ModalHelper.create(
        CollectionFormResult,
        { recordId: data.id, collectionFormId: data.collectionId },
        {
          isChange: true,
          width: "600px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    BatchApply() {
      if (this.selectedRowKeys.length <= 0) {
        abp.message.warn("至少选择一行");
        return false;
      }
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        let index = this.selectedRowKeys[i];
        let row = this.tableData[index];
        if (row.isAuditedStr != "待审核") {
          abp.message.warn("只能对待审核状态的数据进行审核");
          return false;
        }
      }
      this.showBatchApply = true;
    },
    hideBatchApply() {
      this.showBatchApply = false;
    },
    handleSubmit(pass) {
      let ids = [];
      for (var i = 0; i < this.selectedRowKeys.length; i++) {
        let index = this.selectedRowKeys[i];
        ids.push(this.tableData[index].id);
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/PersonnelTask/AuditedTaskRecord",
        method: "POST",
        data: {
          recordIds: ids,
          rejectReason: pass == 0 ? this.rejectReason : "",
          isPass: pass == 0 ? false : true,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            abp.message.success("审核成功");
            this.hideBatchApply();
            this.getPaged();
          } else {
            abp.message.error(res.statusText);
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
</style>
